export default {
    install(Vue/*, options*/) {
        const httpClient = {
            sendHttpRequest(method, url, data, headers, errMsg = null){
                return new Promise((resolve, reject) => {
                    if(method === 'GET') {
                        let qs = '';
                        if(data) qs += '?' +  Object.keys(data).map(key => key + '=' + data[key]).join('&');
                        Vue.http.get(url + qs, data )
                            .then(resp => {
                                if(resp.body.success && !!resp.body.data) resolve(resp.body.data);
                                else {
                                    errMsg ? Vue.$toast.error(errMsg) : Vue.$toast.error(resp.body.msg);
                                    reject(resp.body)
                                }
                            });
                    } else if(method === 'POST') {
                        Vue.http.post(url, data, headers )
                            .then(resp => {
                                if(resp.body.success && !!resp.body.data) resolve(resp.body.data);
                                else {
                                    errMsg ? Vue.$toast.error(errMsg) : Vue.$toast.error(resp.body.msg);
                                    reject(resp.body)
                                }
                            });
                    } else if(method === 'PUT') {
                        Vue.http.put(url, data, headers )
                            .then(resp => {
                                if(resp.body.success && !!resp.body.data) resolve(resp.body.data);
                                else {
                                    errMsg ? Vue.$toast.error(errMsg) : Vue.$toast.error(resp.body.msg);
                                    reject(resp.body)
                                }
                            });
                    } else if(method === 'DELETE') {
                        let qs = '';
                        if(data) qs += '?' +  Object.keys(data).map(key => key + '=' + data[key]).join('&');
                        Vue.http.delete(url + qs)
                            .then(resp => {
                                if (resp.body.success && !!resp.body.data) resolve(resp.body.data);
                                else {
                                    errMsg ? Vue.$toast.error(errMsg) : Vue.$toast.error(resp.body.msg);
                                    reject(resp.body)
                                }
                            });
                    }
                })
            }
        };

        Vue.apix = httpClient;
        Vue.prototype.$apix = httpClient;
    }
}
