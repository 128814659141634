<template lang="pug">
    #app
        Layout
        router-view
        Footer
</template>

<style lang="scss">
    #app {
        /*display: flex;*/
        /*justify-content: space-between;*/
        /*flex-direction: column;*/
    }
</style>

<script>
    import Layout from './components/Layout';
    import Footer from './components/Footer';

    export default {
        components: {Layout, Footer},
        beforeMount(){
            (async () => {
                let content;

                try {
                    content = await this.$apix.sendHttpRequest('GET', 'content/all')

                    let {page, social, testamonials} = content;
                    let pageContent = {};

                    page.forEach(e => {
                        pageContent[e.const_name] = {title: e.name, content: e.content};
                    });

                    console.log(testamonials);

                    await this.$store.dispatch('setTestamonials', testamonials);
                    await this.$store.dispatch('setSocialPage', social);

                    await this.$store.dispatch('setAboutPage', {title: pageContent.About.title, content: pageContent.About.content});
                    await this.$store.dispatch('setDisclaimerPage', {title: pageContent.Disclaimer.title, content: pageContent.Disclaimer.content});
                } catch (e) {
                    console.error('Could not recieve content', e)
                }
            })();
        }
    }
</script>
