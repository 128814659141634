import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

function _constructor(data) {
  return new Vuex.Store({
    state: {
      LOGGED_IN: !!localStorage.getItem('login') && !!localStorage.getItem('pkgid' ) && !!localStorage.getItem('uid') || false,
      LOGGED_PACKAGE: localStorage.getItem('pkgid' ) || null,
      LOGGED_USER: localStorage.getItem('uid') || null,
      PACKAGE_TOKEN: null,
      ABOUT_PAGE: {title: '', content: ''},
      DISCLAMER_PAGE: {title: '', content: ''},
      SOCIAL:{},
      SLICK: {},
      TESTAMONIALS: {},
      SELECTED_ITEM_UUID: localStorage.getItem('ituid') || null,
      ORDER_DETAILS: {},
      STEP: 1
    },
    getters: {
      LOGGED_IN: (state) => {
        return state.LOGGED_IN
      },
      LOGGED_PACKAGE: (state) => {
        return state.LOGGED_PACKAGE
      },
      LOGGED_USER: (state) => {
        return state.LOGGED_USER
      },
      ABOUT_PAGE: (state) => {
        return state.ABOUT_PAGE;
      },
      DISCLAMER_PAGE: (state) => {
        return state.DISCLAMER_PAGE
      },
      SOCIAL: (state) => {
        return state.SOCIAL;
      },
      SLICK: (state) => {
        return state.SLICK
      },
      TESTAMONIALS: (state) => {
        return state.TESTAMONIALS
      },
      SELECTED_ITEM: (state) => {
        return state.SELECTED_ITEM_UUID
      },
      ITEM_ORDER_DETAILS: (state) => {
        return state.ORDER_DETAILS
      },
      WIZARD_STEP: (state) => {
        return state.STEP
      },
    },
    mutations: {
      SET_LOGGED_IN: (state, newValue) => {
        state.LOGGED_IN = newValue
      },
      SET_LOGGED_PACKAGE: (state, newValue) => {
        state.LOGGED_PACKAGE = {...state.LOGGED_PACKAGE, ...newValue}
      },
      SET_LOGGED_USER: (state, newValue) => {
        state.LOGGED_PACKAGE = {...state.LOGGED_USER, ...newValue}
      },
      SET_ABOUT_PAGE: (state, newValue) => {
        state.ABOUT_PAGE = {...state.ABOUT_PAGE, ...{title: newValue.title, content: newValue.content}};
      },
      SET_DISCLAMER_PAGE: (state, newValue) => {
        state.DISCLAMER_PAGE = {...state.DISCLAMER_PAGE, ...{title: newValue.title, content: newValue.content}};
      },
      SET_SOCIAL: (state, newValue) => {
        state.SOCIAL = {...state.SOCIAL, ...newValue};
      },
      SET_TESTAMONIALS: (state, newValue) => {
        state.TESTAMONIALS = {...state.TESTAMONIALS, ...newValue};
      },
      SET_SLICK: (state, newValue) => {
        state.SLICK = newValue;
      },
      SET_SELECTED_ITEM_UUID: (state, newValue) => {
        state.SELECTED_ITEM_UUID = newValue;
      },
      SET_WIZARD_STEP: (state, newValue) => {
        state.WIZARD_STEP = newValue
      },
      SET_ORDER: (state, newValue) => {
        state.ORDER_DETAILS = {...state.ORDER_DETAILS, ...newValue}
      },
    },
    actions: {
      login: ({commit, state}, newValue) => {
        commit('SET_LOGGED_IN', true);
        commit('SET_LOGGED_PACKAGE', newValue.pkgid);
        commit('SET_LOGGED_USER', newValue.uid);
        localStorage.setItem('pkgid', newValue.pkgid);
        localStorage.setItem('uid', newValue.uid);
        localStorage.setItem('login', true);
        return state.LOGGED_IN;
      },
      logout: ({commit, state}) => {
        commit('SET_LOGGED_IN', false);
        commit('SET_LOGGED_PACKAGE', null);
        commit('SET_LOGGED_USER', null);
        localStorage.removeItem('pkgid');
        localStorage.removeItem('uid');
        localStorage.removeItem('login');
        return state.LOGGED_IN;
      },
      setAboutPage: ({commit, state}, newValue) => {
        commit('SET_ABOUT_PAGE', newValue);
        return state.ABOUT_PAGE;
      },
      setDisclaimerPage: ({commit, state}, newValue) => {
        commit('SET_DISCLAMER_PAGE', newValue);
        return state.DISCLAMER_PAGE;
      },
      setSlick: ({commit, state}, newValue) => {
        commit('SET_SLICK', newValue);
        return state.SLICK;
      },
      setSocialPage: ({commit, state}, newValue) => {
        commit('SET_SOCIAL', newValue);
        return state.SOCIAL;
      },
      setTestamonials: ({commit, state}, newValue) => {
        commit('SET_TESTAMONIALS', newValue);
        return state.TESTAMONIALS;
      },
      selectItem: ({commit, state}, newValue) => {
        commit('SET_SELECTED_ITEM_UUID', newValue);
        localStorage.setItem('ituid', newValue);
        return state.SELECTED_ITEM_UUID;
      },
      removeItem: ({commit, state}) => {
        commit('SET_SELECTED_ITEM_UUID', null);
        localStorage.removeItem('ituid');
        return state.SELECTED_ITEM_UUID;
      },
      setStep: ({commit, state}, newValue) => {
        commit('SET_WIZARD_STEP', newValue);
        localStorage.setItem('step', newValue);
        return state.WIZARD_STEP;
      },
      unsetStep: ({commit, state}) => {
        commit('SET_STEP', 1);
        localStorage.removeItem('step');
        return state.WIZARD_STEP;
      },
      setOrderDetails: ({commit, state}, newValue) => {
        commit('SET_ORDER', newValue);
        localStorage.setItem('order', JSON.stringify(newValue));
        return state.ORDER_DETAILS;
      },
      removeOrderDetails: ({commit, state}) => {
        commit('SET_ORDER', null);
        localStorage.removeItem('order');
        return state.ORDER_DETAILS;
      }
    }
  })
}
export default _constructor;
