const ENV = process.env.NODE_ENV;

let API_BASE_URL;
const PAYPAL = {
    sandbox: 'ATFVKQDLEHNlrlKEQip_vdmYOMw6zrjFMVVAGAhes5kMIEZ_hXs0GmuPLIUkSHa8FIMOjZY6XntomZ3x',
    production: 'ATFVKQDLEHNlrlKEQip_vdmYOMw6zrjFMVVAGAhes5kMIEZ_hXs0GmuPLIUkSHa8FIMOjZY6XntomZ3x'
};

if (ENV === 'development') {
    API_BASE_URL = 'http://localhost:9130/api';
} else {
    API_BASE_URL = 'https://buyamazon.devmi.site/api/';
}

export {API_BASE_URL, PAYPAL}
