<template lang="pug">
    .nav-container
        login-modal(v-if="modalVisible", v-on:closed="toggleLoginModal(false)")
        b-navbar.direction-rtl(toggleable='lg', type='dark', variant='info')
            b-navbar-brand(href='#') BuyAmazon
            b-navbar-toggle(target='nav-collapse')
            b-collapse#nav-collapse(is-nav='')
                b-navbar-nav
                    b-nav-item(:to="'/'") עמוד הבית
                    b-nav-item(v-if="!!chosenPackage", :to="'/purchase'") הזמנת המוצר
                    b-nav-item(:to="'/about'") עודותינו
                    b-nav-item(:to="'/disclaimer'") תקנון
                    b-nav-item(:to="'/contact'") צור קשר
                    b-nav-item.v-mob(v-if="!loggedIn", @click="toggleLoginModal(true)") התחבר

                b-navbar-nav.mr-auto
                    b-nav-item-dropdown(v-if="loggedIn", left='')
                        // Using 'button-content' slot
                        template(slot='button-content')
                            em הזמנה
                        b-dropdown-item(:to="'/inventar'") צפה בהזמנה
                        b-dropdown-item(href='#', @click="logout") התנתק
                    b-nav-item-dropdown.v-desktop(v-if="!loggedIn", left="")
                        template(slot="button-content")
                            em התחבר
                        b-form.m-2(@submit.prevent="login")
                            b-form-group(id="login", label="מספר הזמנה/דוא\"ל", label-for="package-inp")
                                b-form-input#package-inp(placeholder="# הזמנה", v-model="form.packageId", v-bind:class="{'is-invalid':error.packageId}")
                            b-form-group(id="login", label='' label-for="email-inp", )
                                b-form-input#package-inp(placeholder="כתובת דוא\"ל",v-model="form.email", v-bind:class="{'is-invalid':error.email}")
                            b-button.w-100(type="submit" variant="warning") כנס

</template>

<style lang="scss">
    .nav-container {
        @media only screen and (max-width: 767px) {
            position: absolute;
            width: 100%;
            .navbar {
                z-index: 500;
            }
        }
    }

    .direction-rtl {
        direction: rtl !important;

        &.bg-info {
            background-color: #03060c !important;
        }

        @media only screen and (min-width: 768px) {
            ul.dropdown-menu.show {
                left: 0;
                right: -75px;
            }
        }

        @media only screen and (max-width: 767px) {
            flex-direction: row-reverse;
            text-align: start;
            ul.navbar-nav {
                padding-right: 0 !important;
            }
        }
    }


</style>
<script>
    import validator from 'validator';
    import loginModal from './LoginModal';
    export default {
        name: 'Layout',
        components: {loginModal},
        data() {
            return {
                modalVisible: false,
                form: {
                    email: '',
                    packageId: ''
                },
                error: {
                    email: false,
                    packageId: false
                }
            }
        },
        computed: {
            loggedIn() {
                return this.$store.state.LOGGED_IN;
            },
            chosenPackage() {
                return this.$store.state.SELECTED_ITEM_UUID;
            },
            loggedPkg() {
                return this.$store.state.LOGGED_PACKAGE;
            }
        },
        methods: {
            login() {
                this.error = {email: false, packageId: false};
                if(!this.form.packageId) this.error.packageId = true;
                if(!this.form.email) this.error.email = true;
                if(!this.form.email || !this.form.packageId) return this.$toast.error('דוא"ל או הזמנה רייקים');
                else if(!validator.isEmail(this.form.email)) {
                    this.error.email = true;
                    return this.$toast.error('פורמט פגום של דוא"ל')
                }

                this.$apix.sendHttpRequest('POST', 'package/auth', this.form)
                    .then((res) => {
                        if(!!res.pkgid && !!res.uid) this.$store.dispatch("login", res);
                        location.href = '/inventar'
                    })
                    .catch((err) => {
                        console.error(err)
                    })

            },
            logout() {
                this.$store.dispatch("logout");
                location.href = '/'
            },
            toggleLoginModal(falg) {
                this.modalVisible = falg;
            }
        },
    }
</script>
