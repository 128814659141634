<template lang="pug">
    b-modal(ref='msg-modal', title='התחברות לאיזור אישי', centered="", no-close-on-backdrop="true")
        template(slot='default', slot-scope='{ hide }')
            b-form.m-2(@submit.prevent="login")
                b-form-group(id="login", label="", label-for="package-inp")
                    b-form-input#package-inp.form-control(placeholder="# הזמנה", v-model="form.packageId", v-bind:class="{'is-invalid':error.packageId}")
                b-form-group(id="login", label='' label-for="email-inp", )
                    b-form-input#package-inp.form-control(placeholder="כתובת דוא\"ל", v-model="form.email", v-bind:class="{'is-invalid':error.email}")
        template(slot='modal-footer', slot-scope='{ ok,	hide }')
            .btn-container.w-100.d-flex.justify-content-center
                b-button.modal-control-buttons.m-2(variant='secondary', block='', @click='toggleModal(false)') סגור
                b-button.modal-control-buttons.m-2(variant='success', block='', @click='login') התחבר
</template>


<style lang="scss">
    .modal-header {
        .close {
            display: none;
            margin: -1rem -1rem -1rem;
        }
    }

    .modal-body {
        background-color: #f7f7f7;
    }

    .dropdown-menu {
        top: 80%;
    }

    @media only screen and (min-width: 576px) {
        .modal-dialog {
            max-width: 850px;
            margin: 1.75rem auto;
        }
    }
</style>


<script>
    import validator from 'validator';
    export default {
        name: 'login-modal',
        data() {
            return {
                form: {
                    email: '',
                    packageId: ''
                },
                error: {
                    email: false,
                    packageId: false
                }
            }
        },
        mounted(){
            this.showModal();
        },
        methods: {
            showModal() {
                this.$refs['msg-modal'].show()
            },
            toggleModal(val) {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.$refs['msg-modal'].toggle('#toggle-btn');
                this.$emit('closed', val);
            },
            login() {
                this.error = {email: false, packageId: false}
                if(!this.form.packageId)  this.error.packageId = true;
                if(!this.form.email)  this.error.email = true;
                if(!this.form.email || !this.form.packageId) return this.$toast.error('דוא"ל או הזמנה רייקים')
                else if(!validator.isEmail(this.form.email)) {
                    this.error.email = true;
                    return this.$toast.error('פורמט פגום של דוא"ל')
                }

                this.$apix.sendHttpRequest('POST', 'package/auth', this.form)
                    .then((res) => {
                        if(!!res.pkgid && !!res.uid) this.$store.dispatch("login", res)
                        location.href = '/inventar'
                    })
                    .catch((err) => {
                        console.error(err)
                    })

            },
            logout() {
                this.$store.dispatch("logout");
                location.href = '/'
            },
        }
    }
</script>
