<template lang="pug">
    .footer
        .links
        .copyright-text
            |   נבנה על ידי  2019
            a.text-white(href="mailto:support@devmi.site")  devmi.site
        .social-links
            .icon-container
                div(v-b-tooltip.html.hover.top="", :title="r.label", v-for="r of social" )
                    a.text-white(:href="r.url")
                        font-awesome-icon.icon.alt.mr-2(:icon="['fab', r.icon]")
                //font-awesome-icon.icon.alt.mr-2(:icon="['fab', 'instagram']")
                //font-awesome-icon.icon.alt.mr-2(:icon="['fab', 'facebook']")
                //font-awesome-icon.icon.alt.mr-2(:icon="['fab', 'telegram-plane']")
</template>


<style lang="scss">
    .footer {
        position:fixed; // important
        bottom:0; // important
        left:0; // important
        width:100%;
        /*height:100px;*/
        z-index: 16;

        display: flex;
        justify-content: space-between;

        background-color: #091a38 !important;
        padding: 1rem 0.1rem !important;
        margin-top: 2rem;

        .copyright-text {
            text-align: center;
            font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
            color: #f9fbfd;
        }

        .social-links {
            padding: 0 1rem;
            color: #f9fbfd;

            .icon-container {
                display: flex;
                justify-content: center;

                .icon {
                    margin: 0 0.5rem ;
                    font-size: 25px;
                    cursor: pointer;
                }
            }
        }
    }
</style>


<script lang="js">
    export default {
        name: 'app-footer',
        computed: {
            social() {
                return this.$store.state.SOCIAL
            }
        }
    }
</script>
