import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Vue-Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'bootstrap-rtl/dist/cdnjs/3.1.1/css/bootstrap-rtl.min.css'
import './styles/style.scss'

Vue.use(BootstrapVue);

// Datepicker
import Datepicker from 'vuejs-datepicker';
Vue.use(Datepicker);

// Vue generic HTTP Client
import VueResource from 'vue-resource'
Vue.use(VueResource);

// Debounce functionality
import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce, {
  defaultTime: '500ms'
});

// Datepicker component
// import datePicker from 'vue-bootstrap-datetimepicker';
// import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// Vue.use(datePicker);

// // Toastr Service
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/index.css';

Vue.use(VueToast, {position: 'top-right'});

// HTTP Client
import apix from './plugins/httpClient';
Vue.use(apix);

import {API_BASE_URL} from './config/index';
Vue.http.options.root =  API_BASE_URL;

// Context
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'
//
import VueSimpleContextMenu from 'vue-simple-context-menu'
Vue.component('vue-simple-context-menu', VueSimpleContextMenu);

// Vue Scroll
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

// Numeric filter
import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller);

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faUser, faUserSecret, faFile, faSearch,
  faFileAlt, faChevronDown, faChevronRight, faMailBulk, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {
    faInstagram,
    faFacebook,
    faTelegramPlane,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faBars, faInstagram, faFacebook,
    faUser, faUserSecret, faFile, faFileAlt,
    faSearch, faMailBulk, faTelegramPlane, faPhoneAlt,
    faChevronDown, faChevronRight, faTwitter);
Vue.component("font-awesome-icon", FontAwesomeIcon);



new Vue({
  router,
  store: store(),
  render: h => h(App)
}).$mount('#app')
