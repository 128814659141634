import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

const withPrefix = (prefix, routes) =>
    routes.map((route) => {
      route.path = prefix + route.path;
      return route;
    });

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/disclaimer',
      name: 'disclaimer',
      component: () => import('./views/Disclaimer')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('./views/Contact')
    },
    {
      path: '/purchase',
      name: 'purchase',
      component: () => import('./views/Purchase')
    },
    {
      path: '/inventar',
      name: 'inventar',
      component: () => import('./views/Inventar')
    },
    {
      path: '/catalog',
      name: 'catalog',
      component: () => import('./views/Catalog')
    },
    {
      path: '/thankyou',
      name: 'thankyou',
      component: () => import('./views/Thankyou')
    },
    {
      path: '/404',
      name: '404',
      component: () => import('./views/404')
    },
    { path: '*', redirect: '/404', hidden: true }
  ]
})
