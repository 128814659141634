<template lang="pug">
    .root.text-right
        item-modal(v-if="showItemModal", v-on:closed="toggleModal(e, selectedItemData)" :item="selectedItemData")
        .jumbotron.pull-right()
            .container-fluid.text-right
                .label
                    h2.moto-text קניה חכמה
                        span &nbsp;רק&nbsp;
                        | אצלינו!
                        small רשת Buy Amazon - קונים בשבילכם מאמזון מאז 2015
        #section-search
            .container
                .row
                    .col-sm-12
                        .search-label
                            label הקלידו את שם המוצר או את הקישור למוצר אותו אתם רוצים לקנות

                        .search-container
                            .input-group
                                input.form-control(type="text", placeholder="קישור או שם המוצר", v-model="itemKeyword", v-debounce:300ms="getItemList")
                                button.btn.btn-warning.search-btn(:disabled="busy", @click.stop="submit") חפש
                                    font-awesome-icon.icon.alt.mr-2(:icon="['fas', 'search']")
                                .options-container.dropdown-menu(v-bind:class="{'d-block':dropdownListOpen}", aria-labelledby="search-users")
                                    label.dropdown-item.text-secondary(v-if="list.length!==0")  --בחר מהרשימה--
                                    div(v-for="(r, i) of list")
                                        small.dropdown-item.text-secondary {{ r.name }}
                                        div
                                            label.dropdown-item.clickable.item-name(v-for="(o, i) of r.data", @click="selectItem(o.uuid)") {{ o.name }}
                                    label.dropdown-item.text-secondary(v-if="list.length===0")  אין רשומות קיימות

            // todo - add dropdown ---------] https://bootstrap-vue.js.org/docs/components/dropdown/
            #section-url-item
                .container
                    .row.mt-5(v-if="!!data.url.product")

                        .col-sm-6
                            .item-desc
                                h5 תיאור המוצר
                                label
                                    strong שם:
                                    | {{ ' ' + data.url.product }}
                        .col-sm-6
                            .item-img
                                img.item-img-unit(:src="data.url.itemPictureUrl")
            #section-slick
                .container
                    .row.mt-5
                        .col-sm-6
                            .text-center
                                h4 עשרת המוצרים פופולאריים שלנו!
                            .popular-slick.clickable.mt-2
                                b-carousel#carousel-1(:interval='4000', controls='', indicators='', background='#ababab', img-width='100%', style='text-shadow: 1px 1px 2px #333;')
                                    b-carousel-slide(v-for="r of statistics.popular", :caption="r.brand", :img-src="r.item_img_url")
                                        template(v-slot:img="")
                                            img(:src="r.item_img_url", alt="image slot", @click="toggleModal(true, r)")

                        .col-sm-6
                            .text-center
                                h4 עשרת המוצרים האחרונים שניקנו!
                            .last-slick.clickable.mt-2
                                b-carousel#carousel-2(:interval='4000', controls='', indicators='', background='#ababab', img-height='480', style='text-shadow: 1px 1px 2px #333;')
                                    b-carousel-slide(v-for="r of statistics.last", :caption="r.brand", :img-src="r.item_img_url")
                                        template(v-slot:img="")
                                            img(:src="r.item_img_url", alt="image slot", @click="toggleModal(true, r)")
            #section-testemonies
                .container
                    .row
                        .col-sm-12
                            h3 מה לקוחותינו אומרים עלינו?
                            hr
                    .testemonies-container
                        b-card.m-2(v-for="r of testamonials", :title="r.name", :img-src="r.img_url", img-alt="image" img-top="", style="max-width: 20rem")
                            b-card-text()
                                .testamony-container
                                    | {{ r.content }}
                                    small.d-block.mt-3 <b>מוצר: </b>{{ r.item.name }}

                        //b-card.m-2(title="Lorem Ipsum", img-src="http://lorempixel.com/400/200/people/",img-alt="image" img-top="", style="max-width: 20rem")
                            b-card-text() Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas feugiat quam eu semper fermentum. Donec auctor ex tempor, venenatis libero id, pulvinar mi. Suspendisse velit urna, interdum sed leo ac, pharetra rhoncus massa. Donec vitae massa aliquet, pellentesque diam eu,

                        //b-card.m-2(title="Dolor Sit Amet", img-src="http://lorempixel.com/600/300/people/",img-alt="image" img-top="", style="max-width: 20rem")
                            b-card-text() elementum ligula. Curabitur at risus vel lorem finibus eleifend non nec ligula. Donec tincidunt lacus ac vestibulum gravida. Morbi nec nisl leo. Praesent posuere molestie justo, non cursus nisl pretium a. Nulla facilisi. Suspendisse tristique id eros quis commodo. Sed ut euismod sem. Maecenas dictum molestie ex at dapibus.

                        //b-card.m-2(title="Proin dapibus", img-src="http://lorempixel.com/300/150/people/",img-alt="image" img-top="", style="max-width: 20rem")
                            b-card-text()  semper fermentum. Donec auctor ex tempor, venenatis libero id, pulvinar mi. Suspendisse velit urna, interdum sed leo ac, pharetra rhoncus massa. Donec vitae massa aliquet, pellentesque diam eu, elementum ligula. Curabitur at risus vel lorem finibus eleifend n nisl pretium a. Nulla facilisi. Suspendisse tristique id eros x at dapibus.

                        //b-card.m-2(title="Etiam eget leo sagittis,", img-src="http://lorempixel.com/800/400/people/",img-alt="image" img-top="", style="max-width: 20rem")
                            b-card-text() Suspendisse pellentesque ligula ac leo commodo, non facilisis dolor varius. Phasellus tempor vel orci in commodo. Vivamus non felis ex. Donec vestibulum lacinia metus et varius. Pellentesque tincidunt dictum scelerisque.
            #section-bottom
                .container
                    .row
                        .col-sm-12
                            h3 דרכי קשר
                            hr
                    .row
                        .col-sm-12
                            label נשמח לעזור בכל שאלה, ניתן לפנות בדוא"ל
                            a(href="mailto:admin@admin.com") admin@admin.com
                            |   או בערוץ טלגרם
                            a(href="https://web.telegram.org/#/im?p=c1177384156_9223711615633924412") am.bot545

</template>

<style lang="scss">

    .popular-slick, .last-slick {

        .carousel-item {
            background: #fff !important;
            height: 100%;
        }

        /*.img-container {*/
            height: 400px !important;
            width: 100%;
            img {
                max-height:300px;
                width: auto !important;
                margin: 10px auto 20px;
                display: block;
            }
        /*}*/
    }

    .jumbotron {
        background-color: #091a38 !important;
        padding: 2rem 1rem !important;
        border-radius: 0 !important;

        h2.moto-text {
            font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
            font-weight: 600;
            font-size: 39px;
            color: #f9fbfd;

            span {
                color: orange;
            }

            small {
                font-size: 2rem;
                margin-right: 1rem;
                margin-top: 1rem;
                /*top: 10px;*/
            }
        }

        @media only screen and (max-width: 767px) {
            padding-top: 5rem !important;
        }
    }

    .container {

        .item-img-unit {
            max-height: 300px
        }

        .search-container {
            button.search-btn {
                color: white;
                margin-right: 0.3rem;
            }

            .item-name {
                overflow: hidden !important;
                text-overflow: ellipsis;
                width: 92vw;
            }
        }

        #section-search {

        }

        .gap-bottom {
            width: 100%;
            padding-bottom: 6rem;
        }

        @media only screen and (max-width: 767px) {
            .gap-bottom {
                padding-bottom: 6rem;
                width: 100% !important;
            }
        }
    }

    #section-testemonies {

        .testemonies-container {
            display: flex;
            justify-content: space-between;
            @media only screen and (max-width: 767px)  {
                flex-direction: column;
                .card {
                    max-width: 100% !important;
                }
            }

            .card-text {

                .testamony-container {
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                }
            }
        }
    }

    #section-bottom {
        margin-top: 3rem;
        margin-bottom: 6rem;
    }
</style>

<script>
    // @ is an alias to /src
    import itemModal from '../components/item-modal';
    import isURL from 'validator/lib/isURL';
    import Vue from 'vue';

    export default {
        name: 'home',
        components: {itemModal},
        data() {
            return {
                showItemModal: false,
                dropdownListOpen: false,
                list: [],
                statistics: {
                    popular: [],
                    last: []
                },
                busy: false,
                itemKeyword: '',
                data: {
                    api: {},
                    url: {}
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            if(to.name === 'home') {
                Vue.apix.sendHttpRequest('GET', 'item/get-statistics/')
                    .then(res => {
                        next(vm => {
                            vm.statistics.popular = res.popular;
                            vm.statistics.last = res.last;
                        })
                    })
                    .catch(err => {
                        window.location.replace('/404');
                        return next(false);
                    })
            } else console.log(to)
        },
        computed:{
            testamonials() {
                return this.$store.state.TESTAMONIALS
            }
        },
        methods: {
            getItemList() {
                if(this.itemKeyword.length === 0) return this.dropdownListOpen = false;
                this.dropdownListOpen = true;
                this.$apix.sendHttpRequest('GET', 'search', {url: this.itemKeyword})
                    .then(res => {

                        let list = res.map((e,i) => {
                            return {name: `${e.category} - ${e.sub_category}`, data: res.filter(r =>  e.sub_category == r.sub_category)}
                        })

                       this.list = list.reduce((acc, current) => {
                            const x = acc.find(item => item.name === current.name);
                            if (!x) {
                                return acc.concat([current]);
                            } else {
                                return acc;
                            }
                        }, []);

                    })
                    .catch(err => console.error(err))
            },
            selectItem(uuid){
                this.$store.dispatch("selectItem", uuid);
                this.$router.push('purchase')
            },
            submit() {
                if (this.busy === true) return;

                if (isURL(this.itemKeyword)) {
                    console.log('true');
                    this.$apix.sendHttpRequest('GET', 'search', {url: this.itemKeyword})
                        .then(res => {
                            console.log(res);
                            this.data.url = res;
                        })
                        .catch(err => console.error(err))
                } else {
                    console.log(false);
                    // this.$toast.error('חייב להיות קישור')
                    this.$apix.sendHttpRequest('GET', 'search', {url: this.itemKeyword})
                        .then(res => {
                            console.log(res);
                            this.data.url = res;
                        })
                        .catch(err => console.error(err))
                }
            },
            toggleModal(event, r) {
                console.log('test');
                this.selectedItemData = r;
                this.showItemModal = event;
            }
        }
    }
</script>
