<template lang="pug">
    b-modal(ref='msg-modal', title='המזנת המוצר', centered="", no-close-on-backdrop="true", size="lg")
        template(slot='default', slot-scope='{ hide }')
            .row.mt-3
                .col-md-8.col-sm-12
                    .row
                        .col-7
                            h5 שם מוצר:
                            label {{ item.name }}
                        .col-5
                            h5 מותג:
                            label {{ item.brand }}

                    .row.mt-3
                        .col-12
                            h5 תיאור המוצר:
                            label {{ item.description }}

                    .row.mt-3
                        .col-12
                            h5 מאפיינים:
                            label {{ item.specifications }}

                    .row.mt-3
                        .col-12
                            h5 הערות:
                            label
                                i {{ item.notes }}


                .col-md-4.col-sm-12
                    .row
                        .col-12
                            .img-container-modal
                                img(:src="item.item_img_url")
                                small.text-secondary.demo-img
                                    i *התמונה להמחשה בלבד
            .row.mt-3
                .col-12

                    h5.d-inline.ml-2 מחיר:
                    .price-container.d-inline
                        label מחיר שלנו לאחר ההנחה:
                            b {{ item.discounted_price | separator }}₪
                        //label מחיר יבוא:
                            b {{ item.total_cost | separator }}$,
                        //label מחיר משלוח:
                            b {{ item.total_cost | separator }}$
        template(slot='modal-footer', slot-scope='{ ok,	hide }')
            .btn-container.w-100.d-flex.justify-content-center
                b-button.modal-control-buttons.m-2(variant='secondary', block='', @click='toggleModal(false)') סגור
                b-button.modal-control-buttons.m-2(variant='success', block='', @click='selectItem(item.uuid)') הזמן עכשיו!

</template>


<style lang="scss">
    .modal-header {
        .close {
            display: none;
            margin: -1rem -1rem -1rem;
        }
    }

    .modal-body {
        text-align: right;
        background-color: #f7f7f7;

        .price-container {
            padding: 0.3rem;
            background-color: rgba(154, 149, 182, 0.31);
            border-radius: 0.6rem;
            border: 1px solid #2c2c2c;
            label {
                /*display: block;*/

            }

            b {
                margin: 0 0.5rem 0 0.5rem;
            }
        }

        .img-container-modal {
            img {
                width: 100%;
            }

            .demo-img {
                margin-top: 1.5rem;
            }


        }
    }
</style>


<script lang="js">
    export default {
        name: 'item-model',
        props: ['item'],
        data(){
            return {
                entity: {}
            }
        },
        mounted(){
            this.showModal();
        },
        methods: {
            showModal() {
                this.$refs['msg-modal'].show()
            },
            toggleModal(val) {
                this.$refs['msg-modal'].toggle('#toggle-btn');
                this.$emit('closed', val);
            },
            selectItem(uuid){
                this.$store.dispatch("selectItem", uuid);
                this.$router.push('purchase')
            },
        },

    }
</script>
